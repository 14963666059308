<template>
  <transition>
    <div class="cardBillDetail" v-loading="fullscreenLoading">
      <span class="card-icon">
        <i class="el-icon-refresh-right" @click="refresh"></i>
      </span>
      <!-- <div class="group">
        <h1 class="title">基本信息</h1>
        <div>
          <table cellspacing="0" cellpadding="0" style="width: 100%">
            <tr>
              <td>ICCID</td>
              <td>{{ iccidMark }}</td>
              <td>套餐类型</td>
              <td>{{ data.mealTypeStr }}</td>
              <td>套餐总流量</td>
              <td>{{totalFlowId}}</td>
            </tr>
            <tr>
              <td>接入号</td>
              <td>{{ data.phone }}</td>
              <td>基础套餐</td>
              <td>{{ data.setMealName }}</td>
              <td>过期时间</td>
              <td>{{ data.expireDate }}</td>
            </tr>
            <tr>
              <td>运营商</td>
              <td>{{ data.accountName }}</td>
              <td>网卡状态</td>
              <td>{{data.statusString}}</td>
              <td>已使用流量</td>
              <td>{{ parseFloat(data.totalBytesCnt).toFixed(2)}}MB</td>
            </tr>
            <tr>
              <td>卡类型</td>
              <td>
                <span v-if="data.type == 1">包月</span>
                <span v-else>包年</span>
              </td>
              <td>供应商</td>
              <td>{{ accountEntity.supplier }}</td>
              <td>
                <span v-if="!data.invNo">剩余流量</span>
              </td>
              <td>
                <span v-if="!data.invNo">{{surplusFlowId}}</span>
              </td>
            </tr>
            <tr>
              <td>导入时间</td>
              <td>{{ importTime }}</td>
              <td>手机绑定</td>
              <td>
                <span v-if="res.phonebind">{{ res.phonebind }}</span>
                <span v-else>未绑定</span>
              </td>
              <td>增加已使用流量百分比<span v-if="res.strategyType==1"> (A)</span><span v-else-if="res.strategyType==2"> (B)</span></td>
              <td>
                <span v-html="curStrategyFormat"></span>
              </td>
            </tr>
            <tr>
              <td>激活时间</td>
              <td>{{ data.servCreateDate }}</td>
              <td>流量池子</td>
              <td>{{ accountEntity.flowPool }}</td>
              <td>已使用流量（真）</td>
              <td>{{ parseFloat(data.orginTotalBytesCnt).toFixed(2) }}MB</td>
            </tr>
            <tr>
              <td>当前套餐</td>
              <td v-html="currentMeal"></td>
              <td>代理商</td>
              <td>
                <span v-if="data.agentName">{{ data.agentThreeName }}</span>
                <span v-else>未分配</span>
              </td>
              <td>生效时间</td>
              <td>{{ res.takeEffectTime }}</td>
            </tr>
            <tr id="yuyin1" v-if="isShow">
              <td>总语音分钟</td>
              <td><span>{{data.voiceSize}}分钟</span></td>
              <td>已使用语音分钟</td>
              <td><span>{{voiceUsed}}</span></td>
              <td>已使用语音分钟(真)</td>
              <td><span>{{origVoiceUsed}}</span></td>
            </tr>
            <tr id="yuyin2" v-if="isShow">
                <td>剩余语音分钟</td>
                <td><span>{{voiceRemaining}}</span></td>
                <td>增加语音百分比</td>
                <td>
                  <span v-if="res.voiceAddDiscount">{{res.voiceAddDiscount}}</span>
                  <span v-else>未设置</span>
                </td>
                <td>运营商状态</td>
                <td>{{yunyingstatusspan}}</td>
            </tr>
            <tr>
              <td>当前限速值</td>
              <td>{{ res.limitSpeed }}</td>
              <td>IMEI</td>
              <td>{{ data.imei }}</td>
              <td>
                <span v-if="data.toType == _getToTypeValue('Y_')">话费</span>
                <span v-else></span>
              </td>
              <td>
                <span v-if="data.toType == _getToTypeValue('Y_')">{{data.balanceMoney}}</span>
                <span v-else></span>
              </td>
            </tr>
            <tr>
              <td>实名状态</td>
              <td>
                  <span v-if="isReal == 1">已实名</span>
                  <span v-else-if="isReal == 2">未实名</span>
                  <span v-else>—未知状态—</span>
                  <i class="el-icon-refresh-right" @click="_isRealName"></i>
              </td>
              <td>
                <span v-if="data.invNo">虚拟号</span>
              </td>
              <td>
                <span v-if="data.invNo">{{data.invNo}}</span>
              </td>
              <td> <span >运营策略</span></td>
              <td>{{res.virtualStrategy}}</td>
            </tr>
          </table>
        </div>
      </div> -->

      <div class="group">
        <h1 class="title">基础信息</h1>
        <div>
           <table cellspacing="0" cellpadding="0" style="width: 100%">
             <tr>
              <td>ICCID</td>
              <td>{{ iccidMark }}</td>
               <td>接入号</td>
              <td>{{ data.phone }}</td>
                <td>短号</td>
              <td>{{ res.shortIccid }}</td>
               <td>IMEI</td>
              <td> <span v-if="data.imei">{{ data.imei }}</span> 
              <span v-else>-</span></td>
              
             </tr>
             <tr>
               <td>套餐类型</td>
              <td>{{ data.mealTypeStr }}</td>
               <td>过期时间</td>
              <td>{{ data.expireDate }}</td>
                <td>运营商</td>
              <td>{{ data.accountName }}</td>
              <td>网卡状态</td>
               <td>{{data.statusString}}</td>
              
             </tr>
             <tr>
               <td>代理商</td>
                <td>
                <span v-if="data.agentName">{{ data.agentThreeName }}</span>
                <span v-else>未分配</span>
              </td>
              <td>供应商</td>
              <td>{{ accountEntity.supplier }}</td>
              <td>导入时间</td>
              <td>{{ importTime }}</td>
              <td>激活时间</td>
               <td><span v-if="data.servCreateDate"> {{ data.servCreateDate }}</span>  
                 <span v-else>-</span> </td>
              
             </tr>
             <tr>
               <td>套餐生效时间</td>
               <td>{{ res.takeEffectTime }}</td>
              <td>卡类型</td>
               <td>
                <span v-if="data.type == 1">包月</span>
                <span v-else>包年</span>
              </td>
              <td>绑定手机</td>
                <td>
                <span v-if="res.phonebind">{{ res.phonebind }}</span>
                <span v-else>未绑定</span>
              </td>
              <td>流量池</td>
              <td> <span  v-if="accountEntity.flowPool">{{ accountEntity.flowPool }}</span> 
              <span v-else>-</span></td>
              
             </tr>
             <tr>
               <td>运营商状态</td>
                <td>  <span v-if="yunyingstatusspan">{{yunyingstatusspan}}</span> 
                <span v-else>-</span> </td>
              <td>当前限速值</td>
               <td>{{ res.limitSpeed }}</td>
               <td>实名状态</td>
                <td>
                  <span v-if="isReal == 1">已实名</span>
                  <span v-else-if="isReal == 2">未实名</span>
                  <span v-else>—未知状态—</span>
                   <el-button icon="el-icon-refresh-right" type="primary" class="themed-button"  @click="_isRealName" style="margin-left:3px"></el-button>
              </td>
              <td>
                <span v-if="data.toType == _getToTypeValue('Y_')">话费</span>
                <span v-else></span>
              </td>
              <td>
                <span v-if="data.toType == _getToTypeValue('Y_')">{{data.balanceMoney}}</span>
                <span v-else></span>
              </td>
             
             </tr>
             <tr>
              <td>
                <span v-if="data.invNo">虚拟号</span>
              </td>
              <td>
                <span v-if="data.invNo">{{data.invNo}}</span>
              </td>
                <td> <span >运营策略</span></td>
              <td>{{res.virtualStrategy}}</td>
              <td>是否轮询</td>
                 <td>
              <span v-if="res.noLoop"> 不在轮询中<span>  {{"("+addSource+ ")"}}</span></span>
              <span v-else>轮询中</span>
              </td>
                <td>上次轮询时间</td>
              <td> <span v-if="res.lastLoopTime"> {{  formatTime(res.lastLoopTime) }}</span>
                   <span v-else>-</span></td>
              
             </tr>
             <tr>
               <td>实名方式</td>
              <td>{{getRealNameType(accountEntity.realNameType)}}</td>
              <td>首次充值时间</td>
              <td><span v-if="data.enabledTime">{{data.enabledTime}}</span>
              <span v-else>-</span></td>
               <td>提前停机</td>
              <td>
                <span v-if="stopAheadDays!=0">{{stopAheadDays+"天"}}</span>
               
                <span v-else>—</span>
                <el-button
                  icon="el-icon-refresh-right"
                  type="primary"
                  class="themed-button"
                  @click="getStopAheadDaysInfo"
                  style="margin-left: 3px"
                ></el-button>
                <!-- <i class="el-icon-refresh-right" @click="_isRealName"></i> -->
              </td>
             </tr>
           </table>
        </div>
      </div>
           <div class="group">
        <h1 class="title">流量信息</h1>
        <div>
           <table cellspacing="0" cellpadding="0" style="width: 100%">
             <tr>
            <td>套餐总流量</td>
            <td>{{totalFlowId}}</td>
             <td>已使用流量</td>
             <td>{{ parseFloat(data.totalBytesCnt).toFixed(2)}}MB</td>
            <td>
                <span v-if="!data.invNo">剩余流量</span>
              </td>
              <td>
                <span v-if="!data.invNo">{{surplusFlowId}}</span>
              </td>
            <td>基础套餐</td>
             <td>{{ data.setMealName }}</td>
             </tr>
             <tr v-if="isShow">
              <td>总语音(分钟)</td>
                 <td><span>{{data.voiceSize}}分钟</span></td>
              <td>已使用语音(分钟)</td>
              <td><span>{{voiceUsed}}</span></td>
              <td>剩余语音(分钟)</td>
                <td><span>{{voiceRemaining}}</span></td>
                <td></td>
                <td></td>
             </tr>
             <tr >
              <td>增加已使用流量百分比<span v-if="res.strategyType==1"> (A)</span><span v-else-if="res.strategyType==2"> (B)</span></td>
              <td>
                <span v-html="curStrategyFormat"></span>
              </td>
               <td>已使用流量（真）</td>
              <td>{{ parseFloat(data.orginTotalBytesCnt).toFixed(2) }}MB</td>
               <td> <span v-if="isShow">增加已使用语音百分比</span> </td>
                <td>
                  <span v-if="isShow">
                    <span v-if="res.voiceAddDiscount">{{res.voiceAddDiscount}}</span>
                  <span v-else>未设置</span>
                  </span>
                  
                </td>
                 <td> <span v-if="isShow">已使用语音(真)</span> </td>
              <td><span v-if="isShow">{{origVoiceUsed}}</span></td>
             </tr>
           </table>
        </div>
      </div>
        <div class="group">
        <h1 class="title">当前套餐</h1>
        <div>
           <table cellspacing="0" cellpadding="0" style="width: 100%">
             <tr>
            <!-- <td v-html="currentMeal"></td> -->
             <td>
                <p>当前生效套餐：</p>
                <span v-html="currentMeal"></span>
                <p>待生效套餐：</p>
                <span v-html="willMeal"></span>
              </td>
             </tr>
           </table>
        </div>
      </div>
      <div class="group"  v-if="currentRole ==0 || isVir">
        <h1 class="title">常规操作</h1>
        <div>
          <ul class="clearfix">
            <li @click="mealRecharge" v-show="!data.invNo">
              <img src="./1.png" alt="" />
              <p>套餐充值</p>
            </li>
            <li @click="cardRecovery" v-show="data.invNo">
              <img src="./3.png" alt="" />
              <p>保号复机</p>
            </li>
            <li @click="cardStop" v-show="data.invNo">
              <img src="./4.png" alt="" />
              <p>保号停机</p>
            </li>
            <li @click="flowDetail">
              <img src="./4.png" alt="" />
              <p>流量详单</p>
            </li>
            <li @click="updateExpire">
              <img src="./5.png" alt="" />
              <p>更改过期时间</p>
            </li>
            <li @click="stopRecoveryRecord">
              <img src="./9.png" alt="" />
              <p>停复机记录</p>
            </li>
            <li @click="orderRecord">
              <img src="./9.png" alt="" />
              <p>往期订单</p>
            </li>
            <li @click="noLoopcard" v-show="data.invNo">
              <img src="./7.png" alt="" />
              <p>不轮询网卡</p>
            </li>
            <li @click="modifyFlow" v-show="data.invNo">
              <img src="./7.png" alt="" />
              <p>增减流量</p>
            </li>
            <li @click="delOnlyLoop" v-show="data.invNo">
              <img src="./7.png" alt="" />
              <p>删除仅轮询</p>
            </li>
               <li @click="returnToZero"  >
              <img src="./7.png" alt="" />
              <p>退货清零</p>
            </li>
              <li @click="clearPluginCards"  >
              <img src="./7.png" alt="" />
              <p>清除插拔卡</p>
            </li>
              <li @click="singleLimit">
                        <img src="./7.png" alt="">
                        <p>单卡限速</p>
                    </li>
          </ul>
        </div>
      </div>

      <el-dialog title="套餐充值" :visible.sync="dialogAddPackage" v-if="dialogAddPackage">
        <add-package
          :addPackageData="addPackageData"
          :curInfor="data"
          @close="dialogAddPackage = false"
        ></add-package>
      </el-dialog>
      <el-dialog title="流量详单" :visible.sync="dialogFlowDetail" v-if="dialogFlowDetail">
        <flow-detail :iccidMark="iccidMark"></flow-detail>
      </el-dialog>
      <el-dialog title="变更过期时间" :visible.sync="dialogUpdateExpire" v-if="dialogUpdateExpire" width="80%">
        <update-expire :cardId="cardId" :iccidMark="iccidMark" @refresh="refresh"></update-expire>
      </el-dialog>
      <el-dialog title="增减流量" :visible.sync="dialogModifyFlow" v-if="dialogModifyFlow"> 
        <change-flow
          :curInfor="data"
          @close="dialogModifyFlow = false"
        ></change-flow>
      </el-dialog>
       <el-dialog title="单卡限速" :visible.sync="dialogSingleSpeed" v-if="dialogSingleSpeed">
        <single-Speed :iccidMark="iccidMark"  @close="dialogSingleSpeed = false"  ></single-Speed>
      </el-dialog>
    </div>
  </transition>
</template>

<script type="text/ecmascript-6">
import AddPackage from "./virtualCardDetail/addPackage"
import FlowDetail from "./virtualCardDetail/flowDetail"
import UpdateExpire from "./virtualCardDetail/updateExpire"
import ChangeFlow from "./virtualCardDetail/changeFlow"
import {changeVirtualStrategy,findCardInfo, getPackageAll,activate_,cardRecovery_,cardStop_,limitStopRecovery_,setThreshold_,deviceCardBound_,tamperRecovery_,noLoopcard_,allowRealname_,openVoice_,delOnlyLoop_,isRealName,statusModify,cardRemarkUpdate,getRemarkByIccid,findSingleCardStopByIccid,returnToZero,clearPluginCards,getStopAheadDays} from "@/api/cardManagement/cardBillDetail.js";

import {findInvCardInfo,findInvCardInfoForAgent} from '@/api/virtualCard/accountRateList.js'

import { messageBox, confirmBox } from "@/utils/common.js"
import loading from "@/utils/Loading.js"
import {mapGetters,mapMutations} from 'vuex'
import dictGroup from '@/mixins/dictGroup.js'
import SingleSpeed from "@/views/cardManagement/cardBillDetail/singleSpeed.vue"
import Utils from '@/utils/utils.js'
export default {
  name:'virtualCardDetail',
  mixins:[dictGroup],
  data() {
    return {
      isVir:false,
      fullscreenLoading:false,
      res: {},
      data: {},
      accountEntity: [],
      cardLiftList: [],
      nextCardLifeList: [],
      tianDjbCardLifes: [],

      dialogAddPackage: false,
      addPackageData: {},
      dialogFlowDetail: false,
      dialogUpdateExpire: false,
      dialogModifyFlow:false,
      strategyType:'1',
      isReal:0,
      totalFlowId:'',
      surplusFlowId:'',
      dialogSingleSpeed:false,
      addSource:"",
      stopAheadDays:0,
    }
  },
  computed: {
    ...mapGetters(
      ['iccidOrPhone_v',"currentRole",]
    ),

    curStrategyFormat(){
      let curStrategy = this.res.curStrategy
      if(curStrategy && curStrategy!=="") {
        if(isNaN(curStrategy)){
          var addStr = "";
          var pairs = curStrategy.split(";")
          var last = 0
          var idx = 0
          var total_count = pairs.length;
          for (var pair of pairs) {
              idx = idx + 1;
              var arr = pair.split(",")
              if (idx === total_count && arr[0] < 100) {
                  addStr = addStr+last+"%以上增加"+arr[1]+"%;<br>";
              } else {
                  addStr = addStr+last+"% ~ "+arr[0]+"%部分增加"+arr[1]+"%;<br>";
              }
              last = arr[0]
          }
          return addStr

        }else{
          return curStrategy + "%"
        }

      }else {
          return "未设置"
      }
    },
    isShow(){
      if (this.data.accountName && (this.data.accountName.startsWith("常州移动") || this.data.accountName.startsWith("重庆移动"))) {
            return false
        }else{
          return true
        }
    },
    yunyingstatusspan(){
      let flag = false
      this.w_ACCOUNT_.forEach(item => {
        if(item.dictValue == this.data.category){
          flag = true
        }
      })
      return flag ? this.res.yunyingstatus : ''

    },
    voiceRemaining(){
      return (this.data.voiceSize - parseInt(this.data.totalBytesCntVoice)).toFixed(0) + "分钟"
    },
    voiceUsed(){
      let flowCnt_voice = parseInt(this.data.totalBytesCntVoice);
      if(flowCnt_voice && flowCnt_voice!=""){
          flowCnt_voice = parseFloat(flowCnt_voice).toFixed(0);
      }
      return flowCnt_voice + "分钟"
    },
    origVoiceUsed(){
      let orginTotalBytesCntVoice = this.data.orginTotalBytesCntVoice;
      if(orginTotalBytesCntVoice && orginTotalBytesCntVoice!=""){
          orginTotalBytesCntVoice = parseFloat(orginTotalBytesCntVoice).toFixed(0);
      }
      return orginTotalBytesCntVoice + "分钟"
    },
    importTime() {
      //导入时间
      let createDateTime = this.data.createDateTime + "";
      return (
        createDateTime.substr(0, 4) +
        "-" +
        createDateTime.substr(4, 2) +
        "-" +
        createDateTime.substr(6, 2) +
        " " +
        createDateTime.substr(8, 2) +
        ":" +
        createDateTime.substr(8, 2) +
        ":" +
        createDateTime.substr(10, 2) +
        ":" +
        createDateTime.substr(12, 2)
      );
    },
     willMeal() {
      console.log(this.cardLiftList, this.nextCardLifeList);
      let msg = "";
      if (this.cardLiftList.length > 0) {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let currentDate = year + "/" + month + "/" + day;
        let currentDate_unix = Date.parse(currentDate);
        this.cardLiftList.forEach((item) => {
          let compareDate = "";
          let compareDate_unix = "";
          if (item.startDate) {
            compareDate = item.startDate.substring(0, 10).replace(/\-/g, "/");
            compareDate_unix = Date.parse(compareDate);
          }
          if (currentDate_unix < compareDate_unix) {
            let effictDate = "";
            if (item.startDate || item.expireDate) {
              effictDate =
                item.startDate.substring(0, 10) +
                "至" +
                item.expireDate.substring(0, 10);
            } else {
              effictDate = "-";
            }
            msg +=
              item.mealName +
              "&nbsp;&nbsp;&nbsp; <strong>有效周期:</strong> " +
              effictDate +
              "&nbsp;&nbsp;&nbsp; <strong>容量: </strong> " +
              item.flowSize +
              "MB " +
              item.voiceSize +
              "分钟<br>";
          }
        });
      }

      if (this.nextCardLifeList.length > 0) {
        //  msg+="待生效套餐<br>"
        this.nextCardLifeList.forEach((item) => {
          msg +=
            item.mealName +
            " " +
            "(次月生效) " +
            "&nbsp;&nbsp;&nbsp; <strong>容量: </strong> " +
            item.flowSize +
            "MB" +
            "<br/>";
        });
      }
      if (this.tianDjbCardLifes.length > 0) {
        this.tianDjbCardLifes.forEach((item) => {
          msg +=
            item.mealName +
            " " +
            "(次月生效) " +
            "&nbsp;&nbsp;&nbsp; <strong>容量: </strong> " +
            item.flowSize +
            "MB" +
            "<br/>";
        });
      }

      return msg;
    },
    currentMeal() {
      //当前套餐
      console.log(this.cardLiftList, this.nextCardLifeList);
      let msg = "";
      if (this.cardLiftList.length > 0) {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let currentDate = year + "/" + month + "/" + day;
        let currentDate_unix = Date.parse(currentDate);
        this.cardLiftList.forEach((item) => {
          let compareDate = "";
          let compareDate_unix = "";
          if (item.startDate) {
            compareDate = item.startDate.substring(0, 10).replace(/\-/g, "/");
            compareDate_unix = Date.parse(compareDate);
          }
          if (currentDate_unix >= compareDate_unix) {
            let effictDate = "";
            if (item.startDate || item.expireDate) {
              effictDate =
                item.startDate.substring(0, 10) +
                "至" +
                item.expireDate.substring(0, 10);
            } else {
              effictDate = "-";
            }
            msg +=
              item.mealName +
              "&nbsp;&nbsp;&nbsp; <strong>有效周期:</strong> " +
              effictDate +
              "&nbsp;&nbsp;&nbsp; <strong>容量: </strong> " +
              item.flowSize +
              "MB " +
              item.voiceSize +
              "分钟<br>";
          }
        });
      }

      return msg;
    },
    gparams() {
      let params = {};
      params.iccidOrPhone = this.iccidMark
      params.setMealId = this.data.setMealId
      params.isChild = this.data.isChild
      params.codeChild = this.data.codeChild
      params.cardType = this.data.category
      params.status = this.data.status
      params.type = this.data.type
      return params
    },
    iccidMark() {
      let params = this.$route.query
      let iccidMark = params.iccidOrPhone
      if(!params.iccidOrPhone){
          iccidMark = this.iccidOrPhone_v
      }

      return iccidMark
    },
    cardId() {
      return this.data.id;
    },
  },
  mounted() {
    this.fullscreenLoading = true
    this._findInvCardInfo()
  },
  methods: {
    ...mapMutations(
        ["SET_ICCID_FOR_TFJ"]
    ),
    getStopAheadDaysInfo(){
       getStopAheadDays({iccid:this.iccidMark}).then(res=>{
          console.log(res)
          this.stopAheadDays  = res.stopAheadDays
       })  
    },
    formatTime(time){
             return Utils.dateFormat('YYYY-mm-dd HH:MM:SS',new Date(time))
         },
          getRealNameType(realNameType){
      console.log(realNameType)
      let realNameTypeStr="-"
        if(realNameType=="4"){
         realNameTypeStr ="不用实名"
        }else if(realNameType=="1"){
          realNameTypeStr ="先实名后充值"
        }else if(realNameType=="2"){
      realNameTypeStr ="先充值后强制实名"
        }else if(realNameType=="3"){
      realNameTypeStr ="可选择下次实名"
        }
        return realNameTypeStr
    },
     singleLimit() { //单卡限速
     this.dialogSingleSpeed = true
    },
    delOnlyLoop() {//删除仅轮询
      this.fullscreenLoading = true
      delOnlyLoop_(this.gparams).then((res) => {
        this._findInvCardInfo()
        this.fullscreenLoading = false
      }).catch(()=>{
        this.fullscreenLoading = false
      });
    },
    modifyFlow() {  //增减流量
      this.dialogModifyFlow = true
    },
    noLoopcard() {  //不轮询网卡
      if (this.res.noLoop) {
        messageBox(this, "该卡已经是不轮询状态。");
        return
      }
      confirmBox(this, "添加成功后该卡将不执行停复机操作同时会出现流量，状态不更新等状态，请谨慎操作！",'操作提示',this._noLoopcard_)
      
    },
      _noLoopcard_() {
      noLoopcard_(this.gparams).then((res) => {
        messageBox(this, "添加成功。");
        this._findInvCardInfo();
      });
    },
    orderRecord() {  //往期订单
      // this.$router.push({
      //   path: "/cardManagement/orderRecord",
      //   query: {
      //     iccidMark: this.iccidMark
      //   },
      // })
      this.$router.push({path:'/cardManagement/orderRecord/'+this.iccidMark}).catch(() => true);
    },
    stopRecoveryRecord() { ///停复机记录
      this.SET_ICCID_FOR_TFJ(this.iccidMark)
      this.$router.push({
        path: "/cardManagement/tingfujiRecord",
        query: {
          iccidMark: this.iccidMark,
        },
      })
    },
    updateExpire() {
      //更改过期时间
      this.dialogUpdateExpire = true;
    },
    flowDetail() {
      //流量详单
      this.dialogFlowDetail = true;
    },
    cardStop() {
      //保号停机
      this.fullscreenLoading = true
      cardStop_(this.gparams).then((res) => {
        this._findInvCardInfo()
        this.fullscreenLoading = false
      }).catch(()=>{
        this.fullscreenLoading = false
      });
    },
    cardRecovery() {
      //保号复机
      this.fullscreenLoading = true
      cardRecovery_(this.gparams).then((res) => {
        this._findInvCardInfo();
        this.fullscreenLoading = false
      }).catch(()=>{
        this.fullscreenLoading = false
      });
    },
     compareResult(expireDate){
      let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let currentDate = year + "/" + month + "/" + day;
        let currentDate_unix = Date.parse(currentDate);
         let compareDate = "";
          let compareDate_unix = "";
          if (expireDate) {
            compareDate = expireDate.substring(0, 10).replace(/\-/g, "/");
            compareDate_unix = Date.parse(compareDate);
          }
          if (currentDate_unix >= compareDate_unix) {//当前时间大于等于过期时间   没有有效套餐
            return  false
          }
          return true
    },
    mealRecharge() {
      //套餐充值
      if (this.res.noRecharge) {
        messageBox(this,"该卡暂不支持充值。")
        return;
      }
      if (this.data.expireDate != "-" && this.compareResult(this.data.expireDate)) {
        confirmBox(this,"该卡已有套餐，确认是否继续充值?","操作提示",()=>{
          this._mealRecharge()
        });
      }else{
          this._mealRecharge()
      }
    },
    _isRealName(){
      isRealName({iccidOrPhone:this.iccidMark}).then((res)=>{
        if (res.isRealName == false){
            this.isReal = 2
        }else if (res.isRealName == true){
            this.isReal = 1
        }
      })
    },
    returnToZero(){
      let params = {};
      params.iccid = this.iccidMark
      returnToZero(params).then((res)=>{
         this.$message({
							showClose: true,
							message: '退货清零成功',
							type: 'success',
						})
      }).catch((error)=>{
          this.$message({
							showClose: true,
							message: '退货清零失败',
              type: 'warning',
						})
      })
    },
    clearPluginCards(){
      let params = {};
      params.iccid = this.iccidMark
      clearPluginCards(params).then(res=>{
         this.$message({
							showClose: true,
							message: '清除插拔卡成功',
							type: 'success',
						})
      }).catch(error=>{
         this.$message({
							showClose: true,
							message: '清除插拔卡失败',
              type: 'warning',
						})
      })
    },
    refresh() {
      this.fullscreenLoading = true
      this._findInvCardInfo()
    },
    _findInvCardInfo() {
      let params = this.$route.query
      if(!params.iccidOrPhone){
          params= {
            iccidOrPhone:this.iccidOrPhone_v
          }
      }
      this.isVir =    params.iccidOrPhone.startsWith("8986888877")
      if(this.currentRole == 0|| this.isVir){
         findInvCardInfo(params).then((res) => {
        this.fullscreenLoading = false
        this.res = res
        this.data = res.entity
        this.accountEntity = res.accountEntity
        this.cardLiftList = res.cardLiftList
        this.nextCardLifeList = res.nextCardLifeList
        this.tianDjbCardLifes = res.tianDjbCardLifes
         
        this.strategyType = res.strategyType + ''
        if(res.noLoop){
        this.addSource = res.addSource
        }
        let currSetmealFlow = 0
        let currSetmealName = ''
        let isLongDiKa = false
        if(this.cardLiftList){
           isLongDiKa = false
          if(this.cardLiftList && this.cardLiftList.length>0) {
            for(var i=0;i<this.cardLiftList.length;i++) {
                // if(isLongDiKa == false && (this.cardLiftList[i].type == 6 || this.cardLiftList[i].type == 23 )) {
                let typeValue = this._getDictValue('ZRLJTC_',this.w_packageType)
                if(isLongDiKa == false && (this.cardLiftList[i].type == typeValue )) {
                    isLongDiKa = true;
                    break;
                }

            }
          }
        }
        this.cardLiftList.forEach((item)=>{
          // if(item.type != 1 && item.type != 101){
          if(item.type != this._getDictValue('DJB_',this.w_packageType) && item.type != this._getDictValue('YYDJB_',this.w_packageType)){
              currSetmealFlow = item.flowSize;
              currSetmealName = item.mealName;
          }
        })

        if(currSetmealFlow == 0  &&!(!isLongDiKa && this.cardLiftList)){
          this.surplusFlowId = "∞ MB"
          this.totalFlowId = "∞ MB"
        }else{
          this.surplusFlowId =  parseFloat(this.data.flowSize - this.data.totalBytesCnt).toFixed(2) +'MB'
          this.totalFlowId = this.data.flowSize + 'MB'
        }

      });
      }else{
           findInvCardInfoForAgent(params).then((res) => {
        this.fullscreenLoading = false
        this.res = res
        this.data = res.entity
        this.accountEntity = res.accountEntity
        this.cardLiftList = res.cardLiftList
        this.nextCardLifeList = res.nextCardLifeList
        this.tianDjbCardLifes = res.tianDjbCardLifes
         
        this.strategyType = res.strategyType + ''
        if(res.noLoop){
        this.addSource = res.addSource
        }
        let currSetmealFlow = 0
        let currSetmealName = ''
        let isLongDiKa = false
        if(this.cardLiftList){
           isLongDiKa = false
          if(this.cardLiftList && this.cardLiftList.length>0) {
            for(var i=0;i<this.cardLiftList.length;i++) {
                // if(isLongDiKa == false && (this.cardLiftList[i].type == 6 || this.cardLiftList[i].type == 23 )) {
                let typeValue = this._getDictValue('ZRLJTC_',this.w_packageType)
                if(isLongDiKa == false && (this.cardLiftList[i].type == typeValue )) {
                    isLongDiKa = true;
                    break;
                }

            }
          }
        }
        this.cardLiftList.forEach((item)=>{
          // if(item.type != 1 && item.type != 101){
          if(item.type != this._getDictValue('DJB_',this.w_packageType) && item.type != this._getDictValue('YYDJB_',this.w_packageType)){
              currSetmealFlow = item.flowSize;
              currSetmealName = item.mealName;
          }
        })

        if(currSetmealFlow == 0  &&!(!isLongDiKa && this.cardLiftList)){
          this.surplusFlowId = "∞ MB"
          this.totalFlowId = "∞ MB"
        }else{
          this.surplusFlowId =  parseFloat(this.data.flowSize - this.data.totalBytesCnt).toFixed(2) +'MB'
          this.totalFlowId = this.data.flowSize + 'MB'
        }

      });
      }
   
    },
    _mealRecharge() {
      loading.show();
      let params = {};
      params.iccidOrPhone = this.iccidMark;
      params.setMealId = this.data.setMealId;
      params.isChild = this.data.isChild;
      params.codeChild = this.data.codeChild;
      getPackageAll(params).then((res) => {
        this.dialogAddPackage = true;
        this.addPackageData = res;
      });
    },
  },
  components: {
    AddPackage,
    FlowDetail,
    UpdateExpire,
    ChangeFlow,
    SingleSpeed,
  },
};
</script>

<style>
ul {
  list-style: none;
}
.cardBillDetail {
  position: relative;
  width: 100%;
  /* height: 100%; */
  background: #fff;
}
.cardBillDetail .card-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: rgba(7, 17, 27, 0.5);
}
.cardBillDetail .card-icon i {
  font-size: 18px;
  font-weight: 700;
  margin-right: 10px;
}
.cardBillDetail .group {
  padding: 25px;
  background: #fff;
}
/* .cardBillDetail .group table {
  border-left: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
} */
.cardBillDetail .group table td {
  height: 32px;
  line-height: 32px;
  /* border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7; */
  text-align: left;
}
.cardBillDetail .group .title {
  height: 32px;
  line-height: 32px;
}
.cardBillDetail ul > li {
  float: left;
  width: 125px;
  text-align: center;
  margin-right: 20px;
  margin-top: 10px;
}
</style>
